var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',[_c('v-card-title',{staticClass:"text-h4 lighten-2"},[_c('v-row',[_c('v-col',[_c('span',{staticClass:"me-2"},[_vm._v(_vm._s(_vm.$t('templates.templates')))])])],1)],1),_c('v-card-text',{staticClass:"mt-5"},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{staticClass:"mr-5",attrs:{"cols":"12","md":"auto"}},[_c('v-btn',{attrs:{"block":"","color":"primary","dense":""},on:{"click":_vm.showHideMessageTemplate}},[_c('v-icon',{attrs:{"dark":"","left":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiMessageText)+" ")]),_vm._v(" "+_vm._s(_vm.$t('templates.addMessageTemplate'))+" ")],1)],1),_c('v-col',{attrs:{"cols":"12","md":"auto"}},[_c('v-btn',{attrs:{"outlined":"","color":"#F15858","block":_vm.$vuetify.breakpoint.mdAndDown,"dense":""},on:{"click":_vm.showCategory}},[_c('v-icon',{attrs:{"dark":"","left":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiFileDocument)+" ")]),_vm._v(" "+_vm._s(_vm.$t('templates.addCategory'))+" ")],1)],1)],1)],1),_c('v-card-text',{staticClass:"mt-5"},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","md":"auto"}},[_c('v-btn',{attrs:{"outlined":"","color":"#F15858","dense":""},on:{"click":_vm.deleteTemplates}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiTrashCan)+" ")])],1)],1),_c('v-col',{staticClass:"mt-8",attrs:{"cols":"11","lg":"3","md":"12","sm":"12"}},[_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"transition":"scale-transition","return-value":_vm.dates,"min-width":"auto"},on:{"update:returnValue":function($event){_vm.dates=$event},"update:return-value":function($event){_vm.dates=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Filter","prepend-icon":_vm.icons.mdiCalendar,"readonly":"","outlined":"","dense":""},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-icon',{on:{"click":_vm.resetDates}},[_vm._v(" "+_vm._s(_vm.icons.mdiClose)+" ")])]},proxy:true}],null,true),model:{value:(_vm.dateRangeText),callback:function ($$v) {_vm.dateRangeText=$$v},expression:"dateRangeText"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{attrs:{"range":"","scrollable":""},model:{value:(_vm.dates),callback:function ($$v) {_vm.dates=$$v},expression:"dates"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"#ffeeee"},on:{"click":function($event){_vm.menu = false}}},[_c('span',{staticClass:"error--text"},[_vm._v(_vm._s(_vm.$t('cancel')))])]),_c('v-btn',{attrs:{"text":"","color":"success"},on:{"click":function($event){return _vm.fetchTemplates()}}},[_vm._v(" OK ")])],1)],1)],1),_c('v-spacer'),_c('v-col',{attrs:{"cols":"12","md":this.$i18n.locale === 'en' ? '2' : '1'}},[_c('v-text-field',{attrs:{"append-icon":_vm.icons.mdiMagnify,"label":_vm.$t('templates.searchTemplate'),"single-line":"","hide-details":"","dense":"","outlined":""},model:{value:(_vm.filterTemplate),callback:function ($$v) {_vm.filterTemplate=$$v},expression:"filterTemplate"}})],1)],1)],1),_c('v-data-table',{staticClass:"text-no-wrap",attrs:{"item-key":"id","headers":_vm.tableHeaders,"items":_vm.templates,"loading":_vm.tableLoading,"loading-text":"Loading... Please wait","show-select":"","footer-props":{
        'items-per-page-text': _vm.$t('rowsPerPageLabel'),
        'items-per-page-options': [5, 10, 25, 50, 75, 100],
      }},scopedSlots:_vm._u([{key:"item.quick_reply",fn:function(ref){
      var item = ref.item;
return [_c('v-checkbox',{on:{"click":function($event){return _vm.changeQuickReply(item)}},model:{value:(item.is_exist),callback:function ($$v) {_vm.$set(item, "is_exist", $$v)},expression:"item.is_exist"}})]}},{key:"item.updated",fn:function(ref){
      var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.$moment(item.updated).format('DD MMM YYYY HH:mm')))])]}},{key:"item.action",fn:function(ref){
      var item = ref.item;
return [_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-btn',{attrs:{"dense":"","icon":""},on:{"click":function($event){return _vm.editTemplate(item.id)}}},[_c('v-icon',{attrs:{"color":"#F15858"}},[_vm._v(" "+_vm._s(_vm.icons.mdiPencilOutline)+" ")])],1)]}}],null,true)})]}}],null,true),model:{value:(_vm.selectedTableData),callback:function ($$v) {_vm.selectedTableData=$$v},expression:"selectedTableData"}})],1),_c('confirm',{ref:"confirm"}),_c('messageTemplate',{ref:"message-template",attrs:{"update-category":_vm.updateCategory},on:{"close-dialog":_vm.fetchTemplates,"show-category":_vm.showCategory,"update-category":function($event){_vm.updateCategory = false}}}),_c('category',{ref:"dialog_category",on:{"update-category":function($event){_vm.updateCategory = true}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }