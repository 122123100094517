<template>
  <div>
    <v-card>
      <!-- title -->
      <v-card-title class="text-h4 lighten-2">
        <v-row>
          <v-col>
            <span class="me-2">{{ $t('templates.templates') }}</span>
          </v-col>
        </v-row>
      </v-card-title>
      <!-- buttons -->
      <v-card-text class="mt-5">
        <v-row dense>
          <v-col cols="12" md="auto" class="mr-5">
            <v-btn block color="primary" dense @click="showHideMessageTemplate">
              <v-icon dark left>
                {{ icons.mdiMessageText }}
              </v-icon>
              {{ $t('templates.addMessageTemplate') }}
            </v-btn>
          </v-col>
          <v-col cols="12" md="auto">
            <v-btn outlined color="#F15858" :block="$vuetify.breakpoint.mdAndDown" dense @click="showCategory">
              <v-icon dark left>
                {{ icons.mdiFileDocument }}
              </v-icon>
              {{ $t('templates.addCategory') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
      <!-- filter & search -->
      <v-card-text class="mt-5">
        <v-row dense>
          <v-col cols="12" md="auto">
            <v-btn outlined color="#F15858" dense @click="deleteTemplates">
              <v-icon dark>
                {{ icons.mdiTrashCan }}
              </v-icon>
            </v-btn>
          </v-col>
          <v-col class="mt-8" cols="11" lg="3" md="12" sm="12">
            <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              transition="scale-transition"
              :return-value.sync="dates"
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="dateRangeText"
                  label="Filter"
                  :prepend-icon="icons.mdiCalendar"
                  readonly
                  outlined
                  v-bind="attrs"
                  dense
                  v-on="on"
                >
                  <template v-slot:append>
                    <v-icon @click="resetDates">
                      {{ icons.mdiClose }}
                    </v-icon>
                  </template>
                </v-text-field>
              </template>
              <v-date-picker v-model="dates" range scrollable>
                <v-spacer></v-spacer>
                <v-btn text color="#ffeeee" @click="menu = false">
                  <span class="error--text">{{ $t('cancel') }}</span>
                </v-btn>
                <v-btn text color="success" @click="fetchTemplates()"> OK </v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="12" :md="this.$i18n.locale === 'en' ? '2' : '1'">
            <v-text-field
              v-model="filterTemplate"
              :append-icon="icons.mdiMagnify"
              :label="$t('templates.searchTemplate')"
              single-line
              hide-details
              dense
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
      <!-- table -->
      <!-- :options.sync="options" -->
      <v-data-table
        v-model="selectedTableData"
        item-key="id"
        :headers="tableHeaders"
        :items="templates"
        :loading="tableLoading"
        loading-text="Loading... Please wait"
        show-select
        class="text-no-wrap"
        :footer-props="{
          'items-per-page-text': $t('rowsPerPageLabel'),
          'items-per-page-options': [5, 10, 25, 50, 75, 100],
        }"
      >
        <!-- <template #[`item.category`]="{ item }">
          <span>{{ item.expand.category ? item.expand.category.name : '' }}</span>
        </template> -->
        <template #[`item.quick_reply`]="{ item }">
          <v-checkbox v-model="item.is_exist" @click="changeQuickReply(item)"></v-checkbox>
        </template>
        <template #[`item.updated`]="{ item }">
          <span>{{ $moment(item.updated).format('DD MMM YYYY HH:mm') }}</span>
        </template>
        <template #[`item.action`]="{ item }">
          <v-menu>
            <template v-slot:activator="{ on, attrs }">
              <v-btn dense icon @click="editTemplate(item.id)">
                <v-icon color="#F15858">
                  {{ icons.mdiPencilOutline }}
                </v-icon>
              </v-btn>
            </template>
          </v-menu>
        </template>
      </v-data-table>
    </v-card>
    <!-- dialog confirmation -->
    <confirm ref="confirm"></confirm>
    <!-- dialog message template -->
    <messageTemplate
      ref="message-template"
      :update-category="updateCategory"
      @close-dialog="fetchTemplates"
      @show-category="showCategory"
      @update-category="updateCategory = false"
    ></messageTemplate>
    <!-- dialog message template -->
    <category ref="dialog_category" @update-category="updateCategory = true"></category>
  </div>
</template>
<script>
import category from '@/components/dialog/message_template/category.vue'
import messageTemplate from '@/components/dialog/message_template/message_template.vue'
import { default as clientPocketBase } from '@/helpers/pocketbase'
import {
  mdiCalendar,
  mdiClose,
  mdiFileDocument,
  mdiMagnify,
  mdiMessageText,
  mdiPencilOutline,
  mdiTrashCan,
} from '@mdi/js'
import axios from 'axios'
import confirm from '../component/Confirm.vue'

export default {
  name: 'Template',
  components: {
    confirm,
    messageTemplate,
    category,
  },
  data() {
    return {
      updateCategory: false,
      icons: {
        mdiMessageText,
        mdiFileDocument,
        mdiTrashCan,
        mdiCalendar,
        mdiMagnify,
        mdiClose,
        mdiPencilOutline,
      },
      menu: false,
      dates: [new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10)],
      filterTemplate: '',
      tableLoading: false,
      selectedTableData: [],
      tableHeaders: [
        {
          text: this.$t('templates.templateName'),
          value: 'template_name',
        },
        {
          text: this.$t('templates.categories'),
          value: 'category',
        },
        {
          text: this.$t('templates.quickReplies'),
          value: 'quick_reply',
        },
        {
          text: this.$t('templates.lastUpdated'),
          value: 'updated',
        },
        {
          text: this.$t('templates.actions'),
          value: 'action',
        },
      ],
      templates: [],
    }
  },
  computed: {
    dateRangeText() {
      return this.dates.join(' ~ ')
    },

    user() {
      // //console.log(
      //   "🚀 ~ file: Chat.vue ~ line 929 ~ user ~ this.$store.getters['auth/getUser']",
      //   this.$store.getters['auth/getUser'],
      // )

      return this.$store.getters['auth/getUser']
    },
  },
  watch: {
    filterTemplate() {
      if (this.timer) {
        clearTimeout(this.timer)
      }

      this.timer = setTimeout(() => {
        this.fetchTemplates()
      }, 800)
    },
  },
  async mounted() {
    this.fetchTemplates()
  },
  methods: {
    async resetDates() {
      this.dates = [this.$moment().subtract(30, 'days').format('YYYY-MM-DD'), this.$moment().format('YYYY-MM-DD')]
      await this.fetchTemplates()
    },
    async editTemplate(id) {
      const refs = this.$refs['message-template']

      const foundTemplate = await clientPocketBase.collection('crm_message_templates').getOne(id, {
        $autoCancel: false,
        expand: 'category',
      })

      refs.dialog.addMessageTEmplate = true
      refs.getDataCategory()
      refs.status_message_template = 'edit'
      refs.dataIndex = 0
      refs.formAddTemplate = foundTemplate
    },
    async fetchTemplates() {
      // set initial filter value
      const filter = ''
      let templates

      // check if filter template have a value
      this.$refs.menu.save(this.dates)

      // fetch from pocketbase with date range filter & template content/name
      try {
        const endpoint = `${process.env.VUE_APP_WA_SERVICES_URL}template/fetch-template`
        const payload = {
          subId: this.user.sub_id,
          filter: this.filterTemplate || '',
          dateStart: this.$moment(this.dates[0]).format('YYYY-MM-DD HH:mm:ss[Z]'),
          dateEnd: this.$moment(this.dates[1]).add(1, 'days').format('YYYY-MM-DD HH:mm:ss[Z]'),
          userId: this.user.id,
        }
        const { data: template } = await axios.post(endpoint, payload)
        templates = template.data
      } catch (err) {
        console.log(err, 'err fetch template')
      }

      // console.log(templates, '<<virr')
      this.templates = templates.template
      this.menu = false
    },

    async deleteTemplates() {
      // check if data singular or plural
      const pronoun = this.selectedTableData.length > 1 ? 'Templates' : 'Template'

      // check data length
      if (this.selectedTableData.length) {
        // open confirm dialog
        this.$refs.confirm
          .open(
            this.$t('delete'),
            `Delete ${this.selectedTableData.length} ${pronoun}
            ?`,
            { color: 'red' },
            this.$t('delete'),
          )
          .then(async agree => {
            if (agree) {
              this.tableLoading = true

              for (let i = 0; i < this.selectedTableData.length; i++) {
                try {
                  const data = this.selectedTableData[i]

                  // const quickReply = await clientPocketBase
                  //   .collection('crm_quick_reply')
                  //   .getFirstListItem(`messages_templates = "${data.id}"`)
                  await axios.post(`${process.env.VUE_APP_POCKETBASE_URL}/q`, {
                    q: `DELETE FROM "crm_quick_reply" WHERE "messages_templates" = "${data.id}"`,
                  })
                } catch (error) {
                  console.log('template not found')
                }
              }

              // map the delete promises
              const deleteTemplates = this.selectedTableData.map(data => {
                return clientPocketBase.collection('crm_message_templates').delete(data.id, {
                  $autoCancel: false,
                })
              })

              // run all delete promise
              await Promise.all(deleteTemplates)

              // re-fetch the data
              this.fetchTemplates()
              this.tableLoading = false
            }
          })
      }
    },

    showHideMessageTemplate() {
      const refs = this.$refs['message-template']
      refs.dialog.addMessageTEmplate = true
      refs.getDataCategory()
      refs.status_message_template = 'add'
      refs.dataIndex = 0
      // eslint-disable-next-line prefer-destructuring
      refs.contactList = refs.contactListItems[0]

      // refs.mustacheList = refs.mustacheAll[refs.contactList.value]
      refs.formAddTemplate = {
        template_name: '',
        category: {},
        language: '',
        message: '',
        quick_reply: true,
        listButton: [],
        link_attachment: '',
        last_update: '',
        attachments: [],
        contact_list: refs.contactListItems[0],
        id: '',
        operator: this.user?.email,
      }
      refs.propsUppyUploader = null
      refs.categoryName = null
    },

    showCategory() {
      const refs = this.$refs.dialog_category
      refs.dialog.category = true
      refs.getDataCategory(true)
    },

    async changeQuickReply(item) {
      // change value quick reply
      try {
        await clientPocketBase.collection('crm_message_templates').update(item.id, {
          quick_reply: item.quick_reply,
        })
      } catch (error) {
        console.log('error update quick reply value')
      }

      // console.log(item, '<<ini tes')
      let getQuickReply

      // if value is true, create new quick reply
      if (item.is_exist) {
        try {
          //  create quick reply from template
          await clientPocketBase.collection('crm_quick_reply').create({
            id: this.$Md5Hash(`${item.id}-${this.user.id}`),
            tag: item.template_name,
            text: item.message,
            sub_id: this.user.sub_id,
            user_id: this.user.id,
            is_global: false,
            messages_templates: item.id,
          })
        } catch (error) {
          console.log(error, 'error create quick reply')
        }
      } else {
        // delete quick reply from template
        const endpoint = `${process.env.VUE_APP_WA_SERVICES_URL}chats/delete-template`
        await axios.post(endpoint, {
          sub_id: this.user.sub_id,
          userId: this.user.id,
          id: item.id,
        })
      }

      // refetch
      this.fetchTemplates()
    },
  },
}
</script>
